import { useCallback, useRef, useState } from 'react'
import { FiGlobe } from 'react-icons/fi'
import { SupportedLocale, SUPPORTED_LOCALES, SwapWidget } from '@uniswap/widgets'
import '@uniswap/widgets/fonts.css'
import { useActiveProvider } from '../connectors'
import { JSON_RPC_URL } from '../constants'
import styles from '../styles/Home.module.css'
import Web3Connectors from './Web3Connectors'
import {MY_TOKEN_LIST} from './tokenlist'

export default function App() {
  const connectors = useRef<HTMLDivElement>(null)
  const focusConnectors = useCallback(() => connectors.current?.focus(), [])
  const provider = useActiveProvider()
  const [locale, setLocale] = useState<SupportedLocale>('en-US')
  const onSelectLocale = useCallback((e) => setLocale(e.target.value), [])
  const default_ = "1";

  const theme3 = {

    // fontFamily: 'Letter Kids',
  
    primary: '#FFFFFF', // основной текст
    secondary: '#888D9B', // мелкий
    interactive: '#FFFFFF', //тикеры 
    container: '#5D5FCE', // задний фон
    module: '#494B9E', // передний фон
    accent: '#DD3DB1', // кнопка купить
    dialog: '#5D5FCE',
    borderRadius: 5,
   // outline: '#FFFFFF',
   // dialog: '#FFFFFF',
   }


  return (
    <>
    <SwapWidget className={styles.uniswap_widget}
              theme={theme3}
              jsonRpcEndpoint={JSON_RPC_URL}
              tokenList={MY_TOKEN_LIST}
              provider={provider}
              locale={locale}
              onConnectWallet={focusConnectors}
              defaultInputTokenAddress="NATIVE"
              defaultInputAmount={default_}
              defaultOutputTokenAddress="0xfeeeef4d7b4bf3cc8bd012d02d32ba5fd3d51e31"
              width={352} 
              
            />
            <br/>
          <div className={styles.title_coonectors}>
          <Web3Connectors />
          </div>
    </>
)
}
