import type { Web3Provider } from '@ethersproject/providers'
import { getPriorityConnector } from '@web3-react/core'
import { Connector } from '@web3-react/types'
import metamask from '../img/metamask.png' 
import walletconnect from '../img/walletconnect.png'

import metaMask, { isMetaMask } from './metaMask'
import walletConnect, { isWalletConnect } from './walletConnect'

export type { Web3Connector } from './utils'

export function Metamask_connect (){
  return(<>CONNECT METAMASK</>)
}

export function getConnectorName(connector: Connector): any {
  if (isMetaMask(connector)) {
    return (<>CONNECT METAMASK <img style={{width: '20px', marginTop: '1px', position: 'absolute'}} src={metamask}/></>)
  } else if (isWalletConnect(connector)) {
    return (<>WALLET CONNECT <img style={{width: '20px', marginTop: '1px', position: 'absolute'}} src={walletconnect}/></>)
  } else {
    throw new Error('Unknown Connector')
  }
}

export const connectors = [metaMask, walletConnect]

export function useActiveProvider(): Web3Provider | undefined {
  return getPriorityConnector(...connectors).usePriorityProvider() as Web3Provider
}
